
import { Vue, Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';
import { DadosAgua, QualidadeAgua } from '@/core/models/agua';
import { QualidadeAguaService } from '@/core/services/agua';
import { ArquivoService } from '@/core/services/geral/ArquivoService';
 
@Component 
export default class ListaQualidade extends mixins(Vue,ListPage) {
  service =  new QualidadeAguaService();
  item = new QualidadeAgua();
  
  loading: boolean = false;
  dialogCadastro: boolean = false; 

  titulo: string = 'Qualidade da água';
  subTitulo: string = 'Qualidade da água cadastrados no Sistema';

  options: any = {
    itemsPerPage: 15
  };  

  headers: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: 'Data de Cadastro', value: 'dataCriacao' },
    { text: 'Responsável', value: 'usuario.login' },
    { text: 'Documento', value: 'documento' }
  ];

  
  breadCrumbLinks: any[] = [
    { text: 'Home', href: '/financeiro/painel/kpi',},
    { text: 'Lista de Qualidade de Água', disabled: true, href: '/financeiro/painel/despesas'}
  ]

  @Watch("options", { deep: true })
  Atualizar() { 
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;
 
    this.service.Listar(page,itemsPerPage,sortBy,sortDesc,search, columns, undefined, '', '', 'Usuario').then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      }, 
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }).finally(() => (this.loading = false));
  }
 
  Novo(){
    this.item = new QualidadeAgua();
    this.dialogCadastro = true; 
  } 

  Editar(item) {  
    this.service.ObterPorId(item.id).then(
      res => { 
        this.item = new QualidadeAgua(res.data);
        this.item.dataCriacao = new Date(res.data.dataCriacao).yyyymmdd();
        this.dialogCadastro = true;
      })
  }

  Clonar(item) {  
    this.service.ObterPorId(item.id).then(
      res => { 
        this.item = new QualidadeAgua(res.data);
        this.item.id = 0;
        this.item.amostraRealizada = new DadosAgua();
        this.item.dataCriacao = new Date(res.data.dataCriacao).yyyymmdd();
        this.dialogCadastro = true;
      })
  }

  DownloadArquivo(item){
    if(!item.documento)
      return this.$swal("Aviso", "Documento não encontrado.", "warning");
    
    new ArquivoService().Download(item.documento);
  }
 
  mounted(){
   // this.Atualizar();
  }
}
